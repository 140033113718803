// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap/dist/js/bootstrap")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'bootstrap';
import '../stylesheets/application';
import $ from 'jquery';
global.$ = $;
global.jQuery = $;

import select2 from 'select2';
import 'select2/dist/css/select2.css';

var documentReady;
documentReady = function(){
  function rescaleCaptcha(){
      var width = $('.g-recaptcha').parent().width();
      var scale;
      if (width < 302) {
        scale = width / 302;
      } else{
        scale = 1.0; 
      }

      $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
      $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
      $('.g-recaptcha').css('transform-origin', '0 0');
      $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
    }

    rescaleCaptcha();
    $( window ).resize(function() { rescaleCaptcha(); });
    
    $('.input-upload').change(function () {
      var attached_files = $(this)[0].files
      var filenames = "";
      
      for (var i = 0; i < attached_files.length; i++) {
        filenames += attached_files[i].name
        if (i != attached_files.length - 1) {
          filenames += "<br />"
        }
      }
      $('.filename').html(filenames)
    });

    $("#locales").change(function(){
      var pathname = window.location.pathname
      window.location.search = "locale="+$(this).val()
    });

    $(".contact_type_input_field").on("click", function(){
      if($("#form-fields-after-select-contact_types").hasClass("hide")){
        $("#form-fields-after-select-contact_types").removeClass("hide");
      }
    });
    
    if($(".contact_type:checked").val() == "complaint"){
      $("#form-fields-after-select-contact_types").removeClass("hide");
      showComplaint();
    }else if($(".contact_type:checked").val() == "consultation"){
      $("#form-fields-after-select-contact_types").removeClass("hide");
      showConsultation();
    }else if($(".contact_type:checked").val() == "add"){
      $("#form-fields-after-select-contact_types").removeClass("hide");
      showAdd();
    }

    $(".contact_type").on("change", function(){
      if(this.value == "consultation"){ showConsultation(); }
      else if(this.value == "complaint"){ showComplaint(); }
      else if(this.value == "add"){ showAdd(); }
      else{ $("#form-fields-after-select-contact_types").addClass("hide"); }
    });       
    
    $('#contact_authorization').select2({
        theme: 'bootstrap4',
        width: '100%',
        minimumResultsForSearch: Infinity
    });
    
    $('#contact_allow_meeting').select2({
        theme: 'bootstrap4',
        width: '100%',
        minimumResultsForSearch: Infinity
    });
    
    $('#contact_allow_notifications').select2({
        theme: 'bootstrap4',
        width: '100%',
        minimumResultsForSearch: Infinity
    });
    
    if($('#contact_allow_notifications').length > 0){
      $('#contact_allow_notifications').on('change', function(){
        showAllowNotificationsBlock(this);
      })
    }
    
    $('#contact_country_id').select2({
        theme: 'bootstrap4',
        width: '100%'
    });
    
    $("[data-action='toggle-checks']").on('click', function(e){
      e.preventDefault();
      toggleChecks(this);
    });
    
}

$(document).on('turbolinks:load', documentReady);

function showComplaint(){
  $('.show-on-consultation').hide();
  $('.show-on-adding').hide();
  $('.show-on-complaint').show();
  unMarkAsRequired('.required-adding-fields');
  unMarkAsRequired('.required-complaint-fields');
  unMarkAsRequired('.required-consultation-fields');
  markAsRequired('.required-complaint-fields');
}

function showConsultation(){
  $('.show-on-adding').hide();
  $('.show-on-complaint').hide();
  $('.show-on-consultation').show();
  unMarkAsRequired('.required-adding-fields');
  unMarkAsRequired('.required-complaint-fields');
  unMarkAsRequired('.required-consultation-fields');
  markAsRequired('.required-consultation-fields');
}

function showAdd(){
  $('.show-on-consultation').hide();
  $('.show-on-complaint').hide();
  $('.show-on-adding').show();
  unMarkAsRequired('.required-adding-fields');
  unMarkAsRequired('.required-complaint-fields');
  unMarkAsRequired('.required-consultation-fields');
  markAsRequired('.required-adding-fields');
}

function unMarkAsRequired(finder){
  if($(finder).find("label").html().split('').pop() == "*"){
    $(finder).find("label").text(function(index, text){
        return text.replace(/\*$/, '');
    })
  }
  $(finder).find("input, textarea").attr("required", false);
}

function markAsRequired(finder){
  if($(finder).find("label").html().split('').pop() != "*"){
    $(finder).find("label").append("*");
  }
  $(finder).find("input, textarea").attr("required", "required");
}

function toggleChecks(elem){
  var form = $(elem).parents("form");
  if(form.find('input[type="checkbox"]:checked').length > 0){
    form.find('input[type="checkbox"]').prop('checked', false);
  }else{
    form.find('input[type="checkbox"]').prop('checked', true);
  }
}

function showAllowNotificationsBlock(elem){
  if(elem.value == 'No'){
    $(".allow_notifications_block").addClass('d-none');
  }else{
    $(".allow_notifications_block").removeClass('d-none');
  }
}
